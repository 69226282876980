<template>
    <!-- component Let's go -->
    <components-let-s-go
        ref="let-s-go"
        :header-title="__isNull(response.route) ? '' :response.route.name"
        :title="$tc('adventure.destination.main', 1)"
        :busy-on="busyOn"
        :button-previous-hidden="true"
        :button-toggle-map-hidden="false"
        :button-next-hidden="true"
        :show-no-hits-message="showNoHitsMessage"
        :toggle-map-prop="toggleMap"
        @eventToggleMap="eventToggleMap"
        @eventLayoutResize="layoutComponentLetsGoResize"
    >
        <!-- content: destinations -->
        <template #content>
            <!-- buttons -->
            <div v-show="!toggleMap" v-for="destination in response.destinations">
                <b-button
                    class="mb-2"
                    style="max-width: 320px; width: 100%; height: 80px;"
                    @click="__gotoExperiences(destination.id)"
                >
                    <div class="d-flex justify-content-around align-items-center">
                        <span style="width: 100%">{{destination.title}}</span>
                        <b-icon-patch-check-fill
                            v-if="destination.solved.solvedPercent !== null && destination.solved.solvedPercent === 100"
                            class="mr-2"
                            variant="white"
                            scale="2"
                        />
                        <span v-else-if="destination.solved.solvedPercent !== null" style="width: 4rem"
                              class="border px-1">{{destination.solved.solvedPercent.toFixed(0).toLocaleString()}} %</span>
                    </div>
                </b-button>
            </div>
            <!-- map -->
            <leaflet-map
                class="text-left"
                v-show="toggleMap"
                mode="let-s-go"
                :map-height="getLayoutSplitpanesHeight.px"
                :map-zoom="10.4"
                :destinations="response.destinations"
                :hot-link="hotLink"
                :bus="busLeafletMap"
                @eventMarkerClick="eventMarkerClick"
            ></leaflet-map>
            <!--                :overlay-visible="overlay.visible"-->
            <!--                @data:reload="prepareSearchData"-->
        </template>
        <!-- content: help -->
        <template #help>
            <div v-html="$t('adventure.help_content.destination')"></div>
            <!-- help: solved -->
            <div class="mt-2">
                {{$t('adventure.help_content.destination_solved')}}
                <b-icon-patch-check-fill variant="white" scale="1.8" class="mx-2 border-secondary"/>
                .
            </div>
        </template>
    </components-let-s-go>
</template>

<script>
import ComponentsLetSGo from "@/components/_public/let-s-go/elements/ComponentLet-s-go";
import LeafletMap from "@/components/_public/_adventure/LeafletMap";
import Vue from "vue";

export default {
    name: "LetGoDestinations",
    components: {LeafletMap, ComponentsLetSGo},
    props: {},
    data() {
        return {
            busyOn: false,
            /**
             * @vuese
             * Use for calculating layout heights and/or widths.
             */
            layout: [],
            /**
             * @vuese
             * URLs for calling api
             */
            uri: {
                getDestinations: `api/v1/let-s-go/destinations`,
            },
            showNoHitsMessage: false,
            response: {},
            toggleMap: true,
            // <--  -->
            /**
             * @vuese
             * Hot link among Adventure, siderExperience and LeafletMap
             */
            hotLink: {
                mode: null,
                destinationId: null,
                experienceId: null,
                popupOpen: false,
                route: null,
                routing: null,
            },
            busLeafletMap: new Vue(),
        }
    },
    mounted() {
        this.loadDataFromBase();
    },
    methods: {
        // <-- layout -->
        /**
         * @vuese
         * Added layout data (width, height) for calculating page or component optimal size (height).
         * @arg event
         */
        layoutComponentLetsGoResize(e) {
            let layout = this.__layoutAdd(this.layout, 'search', e.detail.width, e.detail.height);
        },
        // <-- events -->
        eventToggleMap(toggleMap) {
            this.toggleMap = toggleMap;
            localStorage.setItem('toggleMap', toggleMap);
        },
        // <-- leaflet map -->
        showRoute() {
            if (this.__isNull(this.response)) {
                return;
            }
            let record = this.response;

            // <-- route -->
            let route = {
                id: record.route.id,
            };
            // <-- routing -->
            let routes = [];
            record.destinations.forEach(destination => {
                routes.push({
                    coordinates: {
                        latitude: destination.latlng.lat,
                        longitude: destination.latlng.lng
                    },
                    destination: {
                        id: destination.id,
                        name: destination.title,
                    },
                });
            });
            // <--  -->
            this.hotLink.route = route;
            this.hotLink.routing = routes;
            //
            this.busLeafletMap.$emit('eventShowRoute', {routeName: this.response.route.name});
        },
        eventMarkerClick(data){
            this.__gotoExperiences(data.destinationId)
        },
        // <-- base -->
        /**
         * @vuese
         * Load destinations from database
         */
        loadDataFromBase: function () {
            this.busyOn = true;
            this.showNoHitsMessage = false;
            // <-- api -->
            const config = {
                headers: this.__getHeaders()
            };
            this.axios.get(this.uri.getDestinations + `/${this.$route.params.route}`, config)
                .then((response) => {
                    if (this.__isNull(response.data.destinations)) {
                        this.showNoHitsMessage = true;
                        this.busyOn = false;
                        return;
                    }
                    this.$set(this, 'response', response.data);
                    // <-- showRoute -->
                    this.$nextTick(() => {
                        this.showRoute();
                    });

                    this.busyOn = false;
                })
                .catch((error) => {
                    // console.log("ERROR:", error.response.data);
                });
        },
    },
    computed: {
        // @ignore <-- layout -->
        /**
         * @vuese
         * Optimal height for SiderExperience and map components.
         * @type object
         */
        getLayoutSplitpanesHeight() {
            let value = this.__layoutCenterHeightCalculate.value - this.__layoutHeightCalculate(this.layout);
            value -= 186;
            return {
                value: value,
                px: value + 'px'
            };
        },
    },
    filters: {},
    watch: {}
}
</script>

<style scoped>

</style>
